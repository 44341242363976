import { createUserManager } from '@competencegroup/redux-oidc-es6';
import { store } from '../store.js';

const TENANT_KEY = 'tenant';

const host = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const oidcSettings = {
    client_id: 'tcg-composition',
    redirect_uri: `${host}/callback`,
    response_type: 'token id_token',
    scope: 'openid name api idString',
    authority: 'https://sts.competence.biz/identity',
    silent_redirect_uri: `${host}/silentrenew`,
    post_logout_redirect_uri: `${host}/signedout`,
    automaticSilentRenew: true,
    filterProtocolClaims: false,
    loadUserInfo: false,
    max_age: 1000,
};

export const userManager = createUserManager(oidcSettings);

export const getTenant = () => localStorage.getItem(TENANT_KEY);

export const setTenant = (tenant) => {
    localStorage.setItem(TENANT_KEY, tenant);
}

export const clearTenant = () => {
    localStorage.removeItem(TENANT_KEY);
}

export const ensureUserManagerSettings = async () => {
    const settings = store.getState().env.settings;

    var tenant = getTenant();
    if (!tenant)
        return;

        // for SDB Identity if tenant exists then overrule UserManager with SDB Identity settings else fallback to TCG
    userManager._settings._authority = `https://${tenant}.${settings.sdbIdentityUrl}`;
    userManager._settings._client_id = 'sdblearning-composition';
    userManager._settings._response_type = 'code';
    userManager._settings._scope = 'openid profile sdb';
    userManager._settings._redirect_uri = `${host}/callback/`;
    userManager._settings._silent_redirect_uri = `${host}/silentrenew/`;
    userManager._settings._post_logout_redirect_uri = `${host}/signedout/`;
}
