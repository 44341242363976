export const compositionTypes = {
    COMPOSITIONTYPE_REGULAR: 0,
    COMPOSITIONTYPE_SINGLEITEM: 1,
    COMPOSITIONTYPE_LEGACY: 2
};

export const completionTypes = {
    REQUIRED_BLOCKS: 0,
    ANY_BLOCK: 1,
    ALL_BLOCKS: 2,
    MIN_NUM_BLOCKS: 3,
    NO_SETTINGS: 99
};

export const compoundTypes = {
    SINGLE_ITEM: 'single-item',
    COMPOUND: 'compound',
    COMPOUND_TEST: 'compound-test',
};

export const totalTimeCompleted = (items) => [
        ...items.filter((i) => i.type !== compoundTypes.COMPOUND),
        ...items.filter((i) => i.type === compoundTypes.COMPOUND).map((i) => i.items).flat(),
    ]
    .filter((i) => i.progress === 'completed')
    .reduce((m, i) => m + (i.duration || 0), 0);

export const totalMandatoryItems = (definition, items) => {
    switch (definition.progressSettings?.completionType) {
        case completionTypes.REQUIRED_BLOCKS:
            return items.filter(item => item.compositionSuccessDeterminator === true).length;
        case completionTypes.ANY_BLOCK:
            return 1;
        case completionTypes.MIN_NUM_BLOCKS:
            return definition.progressSettings?.minNumberItems || 0;
        case completionTypes.ALL_BLOCKS:
        case completionTypes.NO_SETTINGS:
        default:
            return items.length;
    }
}

export const totalMandatoryCompletedItems = (definition, items) => {
    switch (definition.progressSettings?.completionType) {
        case completionTypes.REQUIRED_BLOCKS:
            return items.filter(item =>
                item.progress=== 'completed' && item.compositionSuccessDeterminator === true).length;
        case completionTypes.ANY_BLOCK:
        case completionTypes.ALL_BLOCKS:
        case completionTypes.MIN_NUM_BLOCKS:
        case completionTypes.NO_SETTINGS:
        default:
            return items.filter(item => item.progress === 'completed').length;
    }
}
