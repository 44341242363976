import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../store/store.js';
import { initialize } from '../../store/env/env.js';
import { signedInExistingUser } from '../../store/auth/auth.js';
import './sdb-composition-player.js';

@customElement('sdb-embedded-composition-player')
class SdbEmbeddedCompositionPlayer extends connect(store)(LitElement) {

    @property({ type: Object })
    accessor settings;

    @property({ type: Object })
    accessor user;

    @property({ type: String })
    accessor targetId;

    @property({ type: String })
    accessor compositionId;

    @property({ type: String })
    accessor sessionId;

    @property({ type: String })
    accessor type;

    @property({ type: Boolean })
    accessor preview;

    @state()
    accessor ready = false;

    connectedCallback() {
        super.connectedCallback();
        if (this.targetId) {
            this.compositionId = `COMP_${this.targetId.replace(/-/g, '')}`;
        }
    }

    render() {
        if (!this.ready) {
            return nothing;
        }

        return html`
            <store-provider .value=${store}>
                <sdb-composition-player
                    compositionId=${this.compositionId}
                    sessionId=${this.sessionId}
                    type=${this.type}
                    ?preview=${this.preview}>
                </sdb-composition-player>
            </store-provider>
        `;
    }

    stateChanged(state) {
        this.ready = !state.env.loading && !!state.env.settings && !!this.compositionId;;
    }

    firstUpdated() {
        store.dispatch(initialize({ settings: this.settings }));
    }
	
	updated(changedProperties) {
		if (changedProperties.has("user")) {
		store.dispatch(signedInExistingUser({ user: this.user }));
		}
	}
}
