import {
    createAsyncThunk,
    createSlice,
    isPending,
    isRejected
} from '@reduxjs/toolkit';
import { httpGet, httpPost } from '../../helpers/http.js';
import { signalRConnect } from '../signalR/signalR.js';
import {
    completionTypes,
    totalTimeCompleted,
    totalMandatoryItems,
    totalMandatoryCompletedItems,
} from './helpers.js';

export const load = createAsyncThunk(
    'composition/load',
    async ({ compositionId, sessionId, idString }, { getState, dispatch }) => {
        const state = getState();

        const compositionDefinition = await fetchCompositionIfNeeded(compositionId, state);
        const compositionState = await fetchCompositionState(compositionId, sessionId, idString, state);
        await dispatch(signalRConnect({ stateId: compositionState.id }));

        return { compositionDefinition, compositionState, idString};
    },
    {
        condition: (_, { getState }) => {
            const { composition } = getState();

            if (composition.status === 'loading') {
                return false;
            }
        },
    },
);

export const loadPreview = createAsyncThunk(
    'composition/loadPreview',
    async ({ compositionId, type }, { getState }) => {
        const state = getState();

        const compositionDefinition = await fetchCompositionIfNeeded(compositionId, state, type);
        return { compositionDefinition };
    },
    {
        condition: (_, { getState }) => {
            const { composition } = getState();

            if (composition.status === 'loading') {
                return false;
            }
        },
    },
);

export const reloadCompositionState = createAsyncThunk(
    'composition/reloadState',
    async (_, { getState }) => {
        const state = getState();

        var compositionState = await fetchCompositionState(getId(state), null, null, state);
        return { compositionState };
    },
    {
        condition: (_, { getState }) => {
            const { composition } = getState();

            if (composition.status === 'loading') {
                return false;
            }
        },
    },
);

export const resetCompositionState = createAsyncThunk(
    'composition/resetState',
    async ({ compositionId, idString }, { getState }) => {
        const { env, auth } = getState();
        //type: !idString ? COMPOSITION_STATE_RESET : COMPOSITION_STATE_RESET_MULTIPLE,

        const compositionState = await httpPost(
            env.settings.compositionResetEndpoint,
            auth.user,
            {
                compositionId: compositionId,
                idString: idString || null,
                portalId: env.portalIdentifier || null,
            }
        );

        return { compositionState, idString };
    },
);

const fetchCompositionIfNeeded = async (compositionId, state, type) => {
    const { env, composition, auth } = state;

    if (getId(state) === compositionId) {
        return Promise.resolve(composition.definition);
    }

    const url = type === 'draft' ? env.settings.draftEndpoint : env.settings.compositionEndpoint;
    return httpGet(`${url}${compositionId}`, auth.user);
};

const fetchCompositionState = async (compositionId, sessionId, idString, state) => {
    const { env, auth } = state;

    const params = new URLSearchParams();

    let url = `${env.settings.compositionStateEndpoint}${compositionId}`;
    if (sessionId) {
        url += `/${sessionId}`;
    }

    if (env.portalIdentifier) {
        params.set('portalId', env.portalIdentifier);
    }
    if (idString) {
        params.set('idString', btoa(idString));
    }

    if (params.size > 0) {
        url += `?${params}`;
    }

    return httpGet(url, auth.user);
};

const getId = (state) => state.composition?.data?.id;

const combineData = (compositionDefinition, compositionState) => {
    const items = compositionState.items
        ? compositionDefinition.items.map(item => mapItemState(item, compositionState.items))
        : [...compositionDefinition.items];

    // special case, no progress settings defined
    if (compositionDefinition.progressSettings?.completionType === completionTypes.REQUIRED_BLOCKS &&
        items.filter(item => item.compositionSuccessDeterminator === true).length === 0)
        compositionDefinition.progressSettings.completionType = completionTypes.NO_SETTINGS;

    return {
        ...compositionDefinition,
        progress: compositionState.progress,
        success: compositionState.success,
        score: compositionState.score,
        items: items,
        totalTimeCompleted: totalTimeCompleted(items),
        totalMandatoryItems: totalMandatoryItems(compositionDefinition, items),
        totalMandatoryItemsCompleted: totalMandatoryCompletedItems(compositionDefinition, items),
    };
}

const mapItemState = (item, states) => {
    var itemState = states.find(s => {
        return s.compositionItemUniqueId == item.uniqueId;
    });

    return {
        ...item, // remember, spread is shallow
        ...itemState,
        items: item.items ? item.items.map(item => mapItemState(item, states)) : null // merge items
    };
};

const compositionSlice = createSlice({
    name: 'composition',
    initialState: {
        status: 'idle',
        list: {},
        error: null
    },
    reducers: {
        compositionStateChanged: (state, action) => {
            const { payload } = action;

            state.data = combineData(state.definition, payload);
            state.updated = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(load.fulfilled, (state, action) => {
                const { compositionDefinition, compositionState, idString } = action.payload;

                state.status = 'success';
                state.definition = compositionDefinition;

                if (idString) {
                    state.list[idString] = combineData(compositionDefinition, compositionState);
                } else {
                    state.data = combineData(compositionDefinition, compositionState);
                }
            })
            .addCase(loadPreview.fulfilled, (state, action) => {
                const { compositionDefinition } = action.payload;

                state.status = 'success';
                state.definition = compositionDefinition;
                state.data = compositionDefinition;
            })
            .addCase(reloadCompositionState.fulfilled, (state, action) => {
                const { compositionState } = action.payload;

                state.status = 'success';
                state.data = combineData(state.definition, compositionState);
            })
            .addCase(resetCompositionState.fulfilled, (state, action) => {
                const { compositionState, idString } = action.payload;

                state.status = 'success';
                state.resetted = true;

                if (state.definition) {
                    if (idString) {
                        state.list[idString] = combineData(state.definition, compositionState);
                    } else {
                        state.data = combineData(state.definition, compositionState)
                    }
                }
            })
            .addMatcher(isPending(load, loadPreview, reloadCompositionState, resetCompositionState), (state) => {
                state.status = 'loading';
            })
            .addMatcher(isRejected(load, loadPreview, reloadCompositionState, resetCompositionState), (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { compositionStateChanged } = compositionSlice.actions;

export default compositionSlice.reducer;