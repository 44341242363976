import { LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('sdb-elearning-close-dialog')
class SdbElearningCloseDialog extends LitElement {

    constructor() {
        super();

        var appWindow = window.parent; // assume we're loaded in an iframe
        if (appWindow) {
            appWindow.postMessage('close-elearning-modal', '*');
        }
    }

    render() {
        return nothing;
    }
}
