import { compositionStateChanged } from '../composition/composition.js';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export const signalRConnect = createAsyncThunk(
    'signalR/connect',
    async (args, { getState, dispatch }) => {
        const { env } = getState();
        const { stateId } = args;

        let hubConnection = new HubConnectionBuilder()
        .withUrl(`${env.settings.signalRUrl}?stateId=${stateId}`)
        .configureLogging(LogLevel.Information)
        .build();

        await hubConnection.start();

        hubConnection.on('stateChanged', newCompositionState => {
            if (newCompositionState) {
                dispatch(compositionStateChanged(newCompositionState));
            }
        });

        return hubConnection;
    },
    {
        condition: (_, { getState }) => {
            const { signalR } = getState();

            if (signalR.connecting === true) {
                return false;
            }
        },
    },
);

const signalRSlice = createSlice({
    name: 'signalR',
    initialState: {
        status: 'idle',
        hub: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(signalRConnect.pending, (state) => {
                state.status = 'connecting';
            })
            .addCase(signalRConnect.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.hub = payload;
            })
            .addCase(signalRConnect.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export default signalRSlice.reducer;
