import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {styleMap} from 'lit/directives/style-map.js';

@customElement('sdb-icon-badge')
class SdbIconBadge extends LitElement {

    @property({ type: String})
    accessor icon = 'face-smile';

    @property({ type: Number})
    accessor iconSize = 16;

    @property({ type: String})
    accessor status = 'default';

    @property({ type: Boolean})
    accessor iconOnly = false;

    get containerStyles() {
        const size = this.iconSize * 2;
        const offset = this.iconSize;

        return this.iconOnly
        ? {
            width: `${size}px`,
            height: `${size}px`,
        }
        : {
            position: 'absolute',
            top: `-${offset}px`,
            right: `-${offset}px`,
            width: `${size}px`,
            height: `${size}px`,
        };
    }

    static get styles() {
        return css`
            div {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .default {
                background: #444;
            }

            .completed {
                background: #696;
            }

            .failed {
                background: #c66;
            }

            sdb-content-icon {
                color: #fff;
            }
        `;
    }

    render() {
        return html`
            <div
                class=${this.status}
                style=${styleMap(this.containerStyles)}>
                <sdb-content-icon
                    icon="fal fa-${this.icon}"
                    size=${this.iconSize}>
                </sdb-content-icon>
            </div>
        `;
    }
}


