import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { store } from '../store/store.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { Router } from '@lit-labs/router';
import moment from 'moment/dist/moment.js';
import { getLanguage } from '../helpers/translate.js';
import './sdb-route.js';
import './shared/sdb-spinner.js';
import './elearning/sdb-elearning-close-dialog.js';
import '../app/polyfill.js';

@customElement('sdb-main')
class SdbMain extends connect(store)(LitElement) {

    constructor() {
        super();

        this._routes = new Router(this, []);
    }

    static get styles() {
        return css`
            :host {
                font-family: 'Source Sans Pro', 'sans-serif';
            }
        `;
    }

    render() {
        return html`
            <div class="sdb-content">
                <store-provider .value=${store}>
                    <sdb-route></sdb-route>
                </store-provider>
            </div>
        `;
    }

    firstUpdated() {
        moment.locale(getLanguage());
    }
}
