import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { load } from '../../store/composition/composition.js';
import { compositionTypes } from '../../store/composition/helpers.js';
import { launchElearning } from '../../store/elearning/elearning';
import { translate } from '../../helpers/translate.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-overlay-element.js';

const mapStateToProps = state => ({
    composition: state.composition.data,
    ready: !state.composition.isLoading && !!state.composition.data,
});

const mapDispatchToProps = dispatch => ({
    load: (compositionId) => dispatch(load({ compositionId })),
    launchElearning: (item) => dispatch(launchElearning({ item }))
});

@customElement('sdb-composition-start-button')
class SdbCompositionStartButton extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor compositionId;

    @property({type: Boolean})
    accessor disabled = false;

    @property({type: Boolean})
    accessor light = false;

    @state()
    accessor composition;

    @state()
    accessor ready = false;

    static get styles() {
        return css`
            sdb-content-button[light] {
                --text-light: var(--tcg-primary-color-ontertiary, #fff);
            }
        `;
    }

    render() {
        if (!this.ready)
            return nothing;

        return html`
            <sdb-content-button
                label=${this._getCaption()}
                ?disabled=${this.loading || this.disabled}
                ?light=${this.light}
                @click=${(e) => { this._executeStart(e); }}>
            </sdb-content-button>
        `;
    }

    firstUpdated() {
        if (this.compositionId) {
            this.load(this.compositionId);
        }
    }

    _getCaption() {
        switch (this.composition.progress) {
            case 'incomplete': return translate('btnIncomplete');
            case 'completed': return translate('btnCompleted');
            default: return translate('btnStart');
        }
    }

    _executeStart(e) {
        if (this._isSingleItem()) {
            this.launchElearning(this.composition.items[0]);
        }

        this.dispatchEvent(
            new CustomEvent('start', {
                bubbles: true,
                composed: true,
                detail: {
                    isSingleItem: this._isSingleItem(),
                },
                target: e.target
            })
        );
    }

    _isSingleItem() {
        return this.composition.compositionType === compositionTypes.COMPOSITIONTYPE_SINGLEITEM;
    }
}
