import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { launchElearning } from '../../store/elearning/elearning.js';
import { getImageUrl } from '../../helpers/image.js';
import { getIcon, getDuration, getDurationMinMax } from '../../helpers/helpers.js';
import { translate } from '../../helpers/translate.js';
import '@polymer/paper-card';
import '@polymer/paper-button';
import '@polymer/paper-styles/color.js';
import '@polymer/paper-styles/typography.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-icon-pill.js'
import '../shared/sdb-icon-badge.js';

const mapStateToProps = state => ({
    compositionId: state.composition.data.id,
    loading: state.elearning.loading,
});

const mapDispatchToProps = dispatch => ({
    launchElearning: (item) => dispatch(launchElearning({ item }))
});

const MAX_INLINE_ITEMS = 6;
const VISIBLE_INLINE_ITEMS_IF_MENU = 3;

@customElement('sdb-composition-compound-item')
class SdbCompositionCompoundItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Object })
    accessor item;

    @state()
    accessor compositionId;

    @state()
    accessor loading;

    get imageUrl() {
        return getImageUrl(this.compositionId, this.item.image);
    }

    get inlineItems() {
        if (!this.item.items)
            return [];

        if (this.item.items.length <= MAX_INLINE_ITEMS)
            return this.item.items;

        return this.item.items.slice(0, VISIBLE_INLINE_ITEMS_IF_MENU);
    }

    get menuItems() {
        if (!this.item.items)
            return [];

        if (this.item.items.length <= MAX_INLINE_ITEMS)
            return [];

        return this.item.items.slice(VISIBLE_INLINE_ITEMS_IF_MENU);
    }

    static get styles() {
        return css`
            :host {
                --paper-font-subhead_-_font-size: 13px; /* same as paper-button */
                --paper-tooltip-background: #e7e7e7;
                --paper-tooltip-text-color: #444;
            }

            paper-card {
                width: 344px;
                margin-bottom: 15px;
                margin-right: 15px;
                position: relative;
                cursor: default;
            }

            .disabled-overlay {
                z-index:1;
                position: absolute;
                background: rgba(200, 200, 200, .6);
                top:0;
                right:0;
                left:0;
                bottom:0;
                cursor: default;
            }

            .card-header {
                height: 194px;
                position: relative;
            }

            .card-header img, .icon-fallback {
                background: #a269ac;
                width: 100%;
                height: 194px;
            }

            .icon-fallback:not([hidden]) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card-content {
                padding: 10px;
            }

            .icon-fallback iron-icon {
                color: rgba(255, 255, 255, .7);
            }

            .action-bar {
                margin-top: 28px;
                margin-bottom: 5px;
                display: flex;
                align-items: left;
                font-size: 11px;
                color: rgba(0, 0, 0, .87);
                text-transform: uppercase;
            }

            .action {
                cursor: pointer;
                color: #444;
            }

            .title-bar {
                display: flex;
                align-items: center;
                font-size: 18px;
            }

            .title-bar .title {
                flex: 1 1;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            paper-button {
                display: flex;
                flex-direction: column;
            }

            span + span {
                margin-left: 10px;
            }

            .action-button {
                position: relative;
                background-color: rgba(0, 0, 0, .15);
                border-radius: 50%;
                width: 40px;
                height: 40px;
                min-width: 0;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .action-icon {
                position: relative;
                background-color: rgba(0, 0, 0, .54);
                width: 24px;
                height: 24px;
            }

            .dropdown-menu-icon {
                width: 24px;
                height: 24px;
                margin-top: 8px;
                margin-left: 26px;
            }

            paper-item-body {
                margin-left: 10px;
            }

            paper-icon-item {
                margin-left: -55px;
            }

            paper-menu-button {
                padding: 0;
                margin-left: 10px;
            }

            sdb-content-icon.completed {
                color: #669966;
            }

            .tooltip {
                display: flex;
                justify-content: center;
                font-size: 13px;
            }

            .pills {
                position: absolute;
                bottom: 5px;
                right: 5px;
                display: flex;
                justify-content: right;
            }

            sdb-icon-pill:not(:last-child) {
                margin-right: 5px;
            }

            paper-tooltip sdb-icon-pill {
                margin-top: 5px;
            }
        `;
    }

    render() {
        return html`
            <paper-card>
                <div class="card-header">
                    ${this.imageUrl ? html`
                        <img src=${this.imageUrl}>
                    ` : html`
                        <div class="icon-fallback">
                            <sdb-content-icon icon="fal fa-boxes-stacked" size="96"></sdb-content-icon>
                        </div>
                    `}
                    <div class="pills">
                        ${this.item.compositionSuccessDeterminator === true ? html`
                            <sdb-icon-pill
                                icon="seal-exclamation"
                                label=${translate('required')}>
                            </sdb-icon-pill>
                        ` : nothing}
                        <sdb-icon-pill
                            icon="clock"
                            label=${this._getCompoundDuration(this.item)}>
                        </sdb-icon-pill>
                    </div>
                </div>
                <div class="card-content">
                    <div class="title-bar">
                        <span class="title">${this.item.name}</span>
                        ${this.item.progress === 'completed' ? html`
                            <sdb-icon-badge
                                icon="check"
                                iconSize="12"
                                status="completed"
                                iconOnly>
                            </sdb-icon-badge>
                        ` : nothing}
                    </div>
                    <div class="action-bar">

                        ${this.inlineItems.map((item, index) => html`
                            <span id="content_${index}" class="action ${item.type}">
                                <paper-button
                                    id="button_${index}"
                                    ?disabled=${this.loading}
                                    @click=${() => this.launchElearning(item)}
                                    class="action-button">
                                    <sdb-content-icon
                                        icon=${getIcon(item.type)}
                                        size="24">
                                    </sdb-content-icon>
                                    ${item.progress === 'completed' ? html`
                                        <sdb-icon-badge
                                            icon="check"
                                            iconSize="12"
                                            status="completed">
                                        </sdb-icon-badge>
                                    ` : nothing}
                                </paper-button>
                                <paper-tooltip for="button_${index}" position="bottom">
                                    <div class="tooltip">${item.name}</div>
                                    <sdb-icon-pill
                                        icon="clock"
                                        label=${getDuration(item.duration)}>
                                    </sdb-icon-pill>
                                </paper-tooltip>
                            </span>
                        `)}

                        ${this.menuItems.length > 0 ? html`
                            <paper-menu-button dynamic-align vertical-offset="50">
                                <sdb-content-iconbutton
                                    icon="fal fa-ellipsis"
                                    slot="dropdown-trigger"
                                ></sdb-content-iconbutton>
                                <paper-listbox slot="dropdown-content">
                                    ${this.menuItems.map((item) => html`
                                        <paper-icon-item
                                            ?disabled=${this.loading}
                                            @click=${() => this.launchElearning(item)}>
                                            <div class="action-button-icon">
                                                <sdb-content-icon
                                                    icon=${getIcon(item.type)}
                                                    size="24">
                                                </sdb-content-icon>
                                                ${item.progress === 'completed' ? html`
                                                    <sdb-icon-badge
                                                        icon="check"
                                                        iconSize="12"
                                                        status="completed">
                                                    </sdb-icon-badge>
                                                ` : nothing}
                                            </div>
                                            <paper-item-body>
                                                <span>${item.name}</span>
                                            </paper-item-body>
                                        </paper-icon-item>
                                    `)}
                                </paper-listbox>
                            </paper-menu-button>
                        ` : nothing
                        }

                    </div>
                </div>
                <div
                    class="disabled-overlay"
                    ?hidden=${!this.item.disabled}
                    @click=${(e) => { e.stopPropagation(); }}>
                </div>
            </paper-card>
        `;
    }

    _getCompoundDuration(item) {
        if (item.items.length === 1) {
            return getDuration(item.items[0].duration);
        }

        const min = Math.min(...item.items.map(x => x.duration || 0));
        const max = item.items.reduce((s, item) => s + (item.duration || 0), 0);
        return getDurationMinMax(min, max);
    }
}
