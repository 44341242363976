export const httpGet = async (url, user) => {
    return httpRequest('GET', url, user);
}

export const httpPost = async (url, user, data) => {
    return httpRequest('POST', url, user, data);
}

export const httpPut = async (url, user, data) => {
    return httpRequest('PUT', url, user, data);
}

export const httpDelete = async (url, user) => {
    return httpRequest('DELETE', url, user);
}

const httpRequest = async (method, url, user, data) => {
    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': user ? `Bearer ${user.access_token}` : undefined,
        },
        body: data ? JSON.stringify(data) : undefined,
    }

    const res = await fetch(url, options);
    ensureResponseStatus(res);

    try {
        return await res.json();
    } catch {
        return {};
    }
}

export const getAuthorizationHeaders = (user) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${user.access_token}`,
});

export const getAuthorizationHeadersOnly = (user) => ({
    'Authorization': `Bearer ${user.access_token}`,
});

export const ensureResponseStatus = (res, acceptStatusCodes) => {
    if (res.status < 200 || res.status >= 400) {
        if (acceptStatusCodes && acceptStatusCodes.find((code) => code === res.status)) {
            return;
        }

        console.error(res);
        throw new Error(`Http error occurred (${res.status})`);
    }
};
