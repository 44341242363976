import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import connect from 'wc-context-redux/connect';
import { launchElearning } from '../../store/elearning/elearning.js';
import { translate } from '../../helpers/translate.js';
import { getImageUrl } from '../../helpers/image.js';
import { getDuration } from '../../helpers/helpers.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-card';
import '@polymer/paper-button';
import '@polymer/paper-styles/color.js';
import '@polymer/paper-styles/typography.js';
import '../shared/sdb-icon-pill.js'
import '../shared/sdb-icon-badge.js'

const mapStateToProps = state => ({
    compositionId: state.composition.data.id,
    loading: state.elearning.loading,
});

const mapDispatchToProps = dispatch => ({
    launchElearning: (item) => dispatch(launchElearning({ item }))
});

@customElement('sdb-composition-compound-test-item')
class SdbCompositionCompoundTestItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Object })
    accessor item;

    @state()
    accessor compositionId;

    @state()
    accessor loading;

    get imageUrl() {
        return getImageUrl(this.compositionId, this.item.image);
    }

    static get styles() {
        return css`
            paper-card {
                width: 344px;
                margin-bottom: 15px;
                margin-right: 15px;
                position: relative;
                cursor: default;
            }

            .disabled-overlay {
                z-index:1;
                position: absolute;
                background: rgba(200, 200, 200, .6);
                top:0;
                right:0;
                left:0;
                bottom:0;
                cursor: default;
            }

            .card-header {
                height: 194px;
                position: relative;
            }

            .card-header img, .icon-fallback {
                background: #0c7f33;
                width: 100%;
                height: 194px;
            }

            .icon-fallback:not([hidden]) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card-content {
                padding: 10px;
            }

            .icon-fallback iron-icon {
                color: rgba(255, 255, 255, .7);
            }

            .action-bar {
                margin-top: 16px;
                display: flex;
                align-items: left;
                font-size: 11px;
                color: rgba(0,0,0,.87);
                text-transform: uppercase;
            }

            .title-bar {
                display: flex;
                align-items: center;
                font-size: 18px;
            }

            .title-bar .title {
                flex: 1 1;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            paper-tooltip {
                z-index: 3;
            }

            paper-button {
                display: flex;
                flex-direction: column;
            }

            .action-button {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .action-button > sdb-content-icon {
                margin-bottom: 5px;
            }

            .enabled-button {
                color: #444;
                cursor: pointer;
            }

            .disabled-button {
                color: #111;
                cursor: default;
                color: rgba(0,0,0,.40);
            }

            .pills {
                position: absolute;
                bottom: 5px;
                right: 5px;
                display: flex;
                justify-content: right;
            }

            sdb-icon-pill:not(:last-child) {
                margin-right: 5px;
            }
        `;
    }

    render() {
        return html`
            <paper-card>
                <div class="card-header">
                    ${this.imageUrl ? html`
                        <img src=${this.imageUrl}>
                    ` : html`
                        <div class="icon-fallback">
                            <sdb-content-icon
                                icon="fal fa-graduation-cap"
                                class="fallback-icon"
                                size="96">
                            </sdb-content-icon>
                        </div>
                    `}
                    <div class="pills">
                        ${this.item.compositionSuccessDeterminator === true ? html`
                            <sdb-icon-pill
                                icon="seal-exclamation"
                                label=${translate('required')}>
                            </sdb-icon-pill>
                        ` : nothing}
                        ${this.item.duration ? html`
                            <sdb-icon-pill
                                icon="clock"
                                label=${getDuration(this.item.duration)}>
                            </sdb-icon-pill>
                        ` : nothing}
                    </div>
                </div>
                <div class="card-content">
                    <div class="title-bar">
                        <span class="title">${this.item.name}</span>
                        ${choose(this.item.success, [
                            ['passed', () => html`
                                <sdb-icon-badge
                                    icon="check"
                                    iconSize="12"
                                    status="passed"
                                    iconOnly>
                                </sdb-icon-badge>
                            `],
                            ['failed', () => html`
                                <sdb-icon-badge
                                    icon="exclamation"
                                    iconSize="12"
                                    status="failed"
                                    iconOnly>
                                </sdb-icon-badge>
                            `]
                          ],
                          () => nothing,
                        )}
                    </div>
                    <div class="action-bar">
                        ${(this.item.items || []).map((item, index) => html`
                            <span
                                id="content_${index}"
                                class="action"
                                ?hidden=${this._isHidden(this.item.items, index)}>
                                ${this._isEnabled(this.item.items, index)
                                    ? html`
                                        <paper-button
                                            class="action-button enabled-button"
                                            @click=${() => this.launchElearning(item)}>
                                            <sdb-content-icon
                                                icon="fal fa-graduation-cap"
                                                size="24">
                                            </sdb-content-icon>
                                            <span>${item.name}</span>
                                        </paper-button>
                                        ${choose(item.success, [
                                                ['passed', () => html`
                                                    <sdb-icon-badge
                                                        icon="check"
                                                        iconSize="12"
                                                        status="completed">
                                                    </sdb-icon-badge>
                                                `],
                                                ['failed', () => html`
                                                    <sdb-icon-badge
                                                        icon="exclamation"
                                                        iconSize="12"
                                                        status="failed">
                                                    </sdb-icon-badge>
                                                `],
                                            ],
                                            () => nothing,
                                        )}`
                                    : html`
                                        <paper-button
                                            class="action-button disabled-button"
                                            disabled>
                                            <sdb-content-icon
                                                icon="fal fa-graduation-cap"
                                                size="24">
                                            </sdb-content-icon>
                                            <span>${item.name}</span>
                                        </paper-button>`
                                }
                            </span>
                        `)}
                    </div>
                </div>
                <div
                    class="disabled-overlay"
                    ?hidden=${!this.item.disabled} @click=${(e) => { e.stopPropagation(); }}>
                </div>
          </paper-card>
        `;
    }

    _isEnabled(items, index) {
        return index === 0 || items[index - 1].success === 'failed'; // first item or previous item failed
    }

    _isHidden(items, index) {
        return index > 0
            ? items[index - 1].success === 'passed' || this._isHidden(items, index - 1) // previous item is hidden or passed
            : false; // first item
    }
}
