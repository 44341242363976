import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import '@sdblearning/sdb-learning-frontend';
import './player/sdb-embedded-composition-player.js';
import './elearning/sdb-embedded-elearning-dialog.js';

const mapStateToProps = state => ({
    userName: state.oidc.user.profile.name,
    user: state.oidc.user,
    composition: state.composition.data || {},
});

const mapDispatchToProps = dispatch => ({
});

@customElement('sdb-player-app')
class SdbCompositionApp extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor compositionId;

    @property({ type: String })
    accessor sessionId;

    @property({ type: String })
    accessor type;

    @property({ type: Boolean })
    accessor preview;

    @state()
    accessor composition = {};

    static get styles() {
        return css`
            :host {
                --progress-primary: #f49d10;
                --progress-100: green;
            }

            .sdb-header {
                background: #fff;
                height: 70px;
                border-bottom: 1px solid #ddd;
                padding-left: 10%;
                padding-right: 10%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .title {
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: start;
            }

            .chip {
                background: orange;
                padding: 3px 15px 5px 15px;
                margin-left: 20px;
                border-radius: 8px;
                font-size: 16px;
                color: #fff;
                position: relative;
                top: -2px;
            }
        `;
    }

    render() {
        if (this.error)
            return html`
                <style>
                    div {
                        margin: 20px;
                    }
                </style>
                <div>${this.error}</div>
            `;

        if (this.isLoading)
            return html`<div class="spinner"></div>`;

        return html`
            <div class="sdb-header">
                <div class="title">
                    <div>${this.composition.title}</div>
                    <div>${this.preview ? html`<span class="chip">preview</span>` : nothing}</div>
                </div>
                <div>${this.userName}</div>
            </div>
            <sdb-layout-main>
                <sdb-embedded-composition-player
                    .settings=${{ portalIdentifier: 'GLOBAL', env: window.ENVIRONMENT }}
                    .user=${this.user}
                    compositionId=${this.compositionId}
                    sessionId=${this.sessionId}
                    .type=${this.type}
                    ?preview=${this.preview}>
                </sdb-embedded-composition-player>
            </sdb-layout-main>

            <sdb-overlay-element>
                <sdb-embedded-elearning-dialog
                    .settings=${{ portalIdentifier: 'GLOBAL', env: window.ENVIRONMENT }}
                    .user=${this.user}>
                </sdb-embedded-elearning-dialog>
            </sdb-overlay-eleemnt>
        `;
    }
}
