import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../store/store.js';
import { authenticate } from '../store/auth/auth.js';
import { setTenant, clearTenant } from '../store/auth/userManager.js'
import { Routes } from '@lit-labs/router';
import './authentication/sdb-authenticate-callback.js';
import './authentication/sdb-authenticate-silent-renew.js';
import './sdb-player-app.js';
import './sdb-player-demo-app.js';
import './elearning/sdb-elearning-close-dialog.js';

@customElement('sdb-route')
class SdbRoute extends connect(store)(LitElement) {

    constructor() {
        super();

        this._matchingIdString = undefined;
        this._needsAuthentication = false;
        this._authenticated = false;

        this._routes = new Routes(this, [
            {
                path: '/silentrenew',
                render: () => html`<sdb-authenticate-silent-renew></sdb-authenticate-silent-renew>`,
            },
            {
                path: '/callback',
                render: () => html`<sdb-authenticate-callback @redirect=${(e)=> { this._goto(e.detail.path); }}></sdb-authenticate-callback>`,
            },
            {
                path: '/composition/:id/:idString/:sessionId?',
                render: ({ id, sessionId }) => this._authenticatedRender(html`
                    <sdb-player-app
                        compositionId=${id}
                        sessionId=${sessionId}>
                    </sdb-player-app>
                `),
                enter: async ({ idString }) => {
                    this._needsAuthentication = true;
                    this._matchIdString(idString);
                    clearTenant();
                },
            },
            {
                path: '/preview/:type/:id',
                render: ({ id, type }) => this._authenticatedRender(html`
                    <sdb-player-app
                        compositionId=${id}
                        type=${type}
                        preview>
                    </sdb-player-app>
                `),
                enter: async () => {
                    this._needsAuthentication = true;
                    clearTenant();
                },
            },
            {
                path: '/demo/:id/:idString?',
                render: ({ id, type, idString }) => this._authenticatedRender(html`
                    <sdb-player-demo-app
                        targetId=${id}
                        type=${type}
                        idString=${idString ? atob(idString) : undefined}>
                    </sdb-player-demo-app>
                `),
                enter: async () => {
                    this._needsAuthentication = true;
                    clearTenant();
                },
            },
            {
                path: '/embedded/:id/:tenant',
                render: ({ id }) => this._authenticatedRender(html`
                    <sdb-player-app compositionId=${id}></sdb-player-app>
                `),
                enter: async ({ tenant }) => {
                    this._needsAuthentication = true;
                    setTenant(tenant);
                },
            },
            {
                path: '/elearning-close-dialog',
                render: () => html`<sdb-elearning-close-dialog></sdb-elearning-close-dialog>`,
            },
            {
                path: '/*',
                render: () => html`<div style="margin: 20px;">unknown route</div>`,
            },
        ]);
    }

    render() {
        return html`<div>${this._routes.outlet()}</div>`;
    }

    stateChanged(state) {
        if (state.oidc.isLoadingUser || state.auth.redirecting || !this._needsAuthentication || this._authenticated) {
            return;
        }

        if (state.auth.signedIn === true) {
            this._authenticated = true;
            this.requestUpdate();
        } else {
            store.dispatch(authenticate({ matchingIdString: this._matchingIdString }));
        }
    }

    _matchIdString(idString) {
        this._matchingIdString = idString;
    }

    async _goto(path) {
        await this._routes.goto(path);
        window.history.pushState({}, '', path);
    }

    _authenticatedRender(authenticatedHtml) {
        return !this._authenticated ? html`<div class="spinner"></div>` : authenticatedHtml;
    }
}
