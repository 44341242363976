import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { launchElearning } from '../../store/elearning/elearning.js';
import { translate } from '../../helpers/translate.js';
import { getImageUrl } from '../../helpers/image.js';
import { getIcon, getDuration } from '../../helpers/helpers.js';
import '@polymer/paper-card';
import '@polymer/paper-button';
import '@polymer/paper-styles/color.js';
import '@polymer/paper-styles/typography.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-icon-pill.js'

const mapStateToProps = state => ({
    compositionId: state.composition.data.id,
    loading: state.elearning.loading,
});

const mapDispatchToProps = dispatch => ({
    launchElearning: (item) => dispatch(launchElearning({ item }))
});

@customElement('sdb-composition-item')
class SdbCompositionItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Object })
    accessor item;

    @state()
    accessor compositionId;

    @state()
    accessor loading;

    get imageUrl() {
        return getImageUrl(this.compositionId, this.item.image);
    }

    static get styles() {
        return css`
            paper-card {
                width: 344px;
                margin-bottom: 15px;
                margin-right: 15px;
                position: relative;
                cursor: default;
            }

            .disabled-overlay {
                z-index:2;
                position: absolute;
                background: rgba(200, 200, 200, .6);
                top:0;
                right:0;
                left:0;
                bottom:0;
                cursor: default;
            }

            .card-header {
                height: 194px;
                position: relative;
            }

            .card-header img, .icon-fallback {
                background: #0c7f33;
                width: 100%;
                height: 194px;
            }

            .card-header.movie {
                background: #1f0c7f;
            }

            .card-header.infographic {
                background: #7f0c7f;
            }

            .card-header.pretest {
                background: #66a5be;
            }

            .card-header.movie {
                background: #7f590c;
            }

            .card-header.test {
                background: #0c7f33;
            }

            .card-header.elearning {
                background: #0c6c7f;
            }

            .card-header iron-icon {
                color: rgba(255, 255, 255, .7);
            }

            .icon-fallback:not([hidden]) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card-content {
                padding: 10px 10px 10px 12px;
            }

            .title-bar {
                display: flex;
                align-items: center;
                font-size: 18px;
                height: 24px;
            }

            .title-bar .title {
                flex: 1 1;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .action-bar {
                margin-top: 19px;
                margin-bottom: 0;
                display: flex;
                align-items: left;
                font-size: 13px;
                color: rgba(0,0,0,.87);
                text-transform: uppercase;
            }

            sdb-content-icon.completed {
                color: #669966;
            }

            .pills {
                position: absolute;
                bottom: 5px;
                right: 5px;
                display: flex;
                justify-content: right;
            }

            sdb-icon-pill:not(:last-child) {
                margin-right: 5px;
            }
        `;
    }

    render() {
        return html`
            <paper-card>
                <div class="card-header ${this.item.type}">
                    ${this.imageUrl ? html`
                        <img src=${this.imageUrl}>
                    ` : html`
                        <div class="icon-fallback" ?hidden=${this.item.image}>
                            <sdb-content-icon
                                icon=${getIcon(this.item.type)}
                                size="96">
                            </sdb-content-icon>
                        </div>
                    `}
                    <div class="pills">
                        ${this.item.compositionSuccessDeterminator === true ? html`
                            <sdb-icon-pill
                                icon="seal-exclamation"
                                label=${translate('required')}>
                            </sdb-icon-pill>
                        ` : nothing}
                        ${this.item.duration ? html`
                            <sdb-icon-pill
                                icon="clock"
                                label=${getDuration(this.item.duration)}>
                            </sdb-icon-pill>
                        ` : nothing}
                    </div>
                </div>
                <div class="card-content">
                <div class="title-bar">
                    <span class="title">${this.item.name}</span>
                    ${this.item.progress === 'completed' ? html`
                        <sdb-icon-badge
                            icon="check"
                            iconSize="12"
                            status="completed"
                            iconOnly>
                        </sdb-icon-badge>
                    ` : nothing}
                </div>
                <div class="action-bar">
                    <sdb-content-button
                        outlined
                        label=${this._getCaption(this.item)}
                        ?disabled=${this.loading}
                        @click=${() => { this.launchElearning(this.item); }}>
                    </sdb-content-button>
                </div>
                <div
                    class="disabled-overlay"
                    ?hidden=${!this.item.disabled}
                    @click=${(e) => { e.stopPropagation(); }}>
                </div>
            </paper-card>
        `;
    }

    _getCaption(item) {
        switch (item.progress) {
            case 'incomplete': return translate('btnIncomplete');
            case 'completed': return translate('btnCompleted');
            default: return translate('btnStart');
        }
    }
}
