import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import connect from 'wc-context-redux/connect';
import { load, resetCompositionState } from '../../store/composition/composition';
import { translate } from '../../helpers/translate.js';
import { compositionTypes } from '../../store/composition/helpers.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-dialog';
import '../shared/sdb-overlay-element.js';

const mapStateToProps = state => ({
    composition: state.composition.data,
    isLoading: state.composition.isLoading ,
    noProgress: !state.composition.data || !state.composition.data.progress || state.composition.data.progress === 'started'
});

const mapDispatchToProps = dispatch => ({
    load: (compositionId) => dispatch(load({ compositionId })),
    resetComposition: (compositionId) => dispatch(resetCompositionState({ compositionId }))
});

@customElement('sdb-composition-reset-button')
class SdbCompositionResetButton extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor targetId;

    @property({ type: String })
    accessor compositionId;

    @property({ type: String })
    accessor label;

    @property({ type: Boolean })
    accessor disabled = false;

    @property({type: Boolean})
    accessor light = false;

    @state()
    accessor noProgress;

    @state()
    accessor composition;

    @state()
    accessor isLoading;

    get buttonLabel() {
        return this.label || translate('btnReset');
    }

    static get styles() {
        return css`
            sdb-content-button[light] {
                --text-light: var(--tcg-primary-color-ontertiary, #fff);
            }
        `;
    }

    render() {
        if (this.noProgress === true) {
            return nothing;
        }

        if (this.isLoading) {
            return html`
                <sdb-content-button
                    label=${this.buttonLabel}
                    disabled>
                </sdb-content-button>
            `;
        }

        return html`
            <sdb-content-button
                label=${this.buttonLabel}
                ?outlined=${!this.disabled}
                ?disabled=${this.loading || this.disabled}
                ?light=${this.light}
                @click=${(e) => { this._openResetDialog(e); }}>
            </sdb-content-button>
            <sdb-overlay-element>
                <paper-dialog id="sdb-composition-reset-dialog" modal>
                    <h2>${translate('dlgResetHeader')}</h2>
                    <p>${unsafeHTML(translate('dlgResetBody', { catalogName: this.composition.title }))}</p>
                    <div class="buttons">
                        <paper-button dialog-dismiss>${translate('btnCancel')}</paper-button>
                        <paper-button
                            dialog-confirm
                            autofocus
                            @click=${this._executeReset}>${translate('btnReset')}</paper-button>
                    </div>
                </paper-dialog>
            </sdb-overlay-element>
        `;
    }

    firstUpdated() {
        if (this.compositionId) {
            this.load(this.compositionId);
        }
    }

    _openResetDialog() {
        // our dialog can now be found in the regular dom
        document.getElementById('sdb-composition-reset-dialog').opened = true;
    }

    _executeReset(e) {
        this.resetComposition(this.composition.id);

        this.dispatchEvent(
            new CustomEvent('reset', {
                bubbles: true,
                composed: true,
                detail: {
                    isSingleItem: this.composition.compositionType === compositionTypes.COMPOSITIONTYPE_SINGLEITEM
                                  && this.composition.items.length > 0,
                },
                target: e.target
            })
        );
    }
}
